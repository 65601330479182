

<template>
	<div class="previewapp-wrapper">
		<!--中间-->
		<div v-if="this.name" :class="getContentClass()">
			<div :class="getLeftClass()">
				<!--手机上查看-->
				<div v-if="isPhone" v-show="isPhone">
					<p>手机</p>
					<phoneWrapper :info="info" @clickDownLoadBtn="clickDownLoadBtn"></phoneWrapper>
					<!-- <p style="font-size:20px" v-show="!isSafari">使用Safari打开下载页面,才可以正常下载</p> -->
				</div>

				<!--pc上查看-->
				<div v-show="!isPhone" class="pcWrapper">
					<img class="appicon" :src="getIconUrl()" alt="" />
					<p class="title">{{ this.name }}</p>
					<div v-if="iosVersion">
						<div class="info">
							<p v-if="this.iosVersion.versionStr" class="desc">iOS版本：{{ this.iosVersion.versionStr }}</p>
							<span>大小：{{ (this.iosVersion.size / 1024 / 1024).toFixed(1) }}M</span>
						</div>
						<p class="date">Build： {{ this.iosVersion.versionCode }}</p>
						<p class="date">发布日期： {{ this.iosVersion.creatDateStr }}</p>
						<!-- <div v-if="showPasswordInput">
							<el-input v-model="pwd" type="password" placeholder="请输入密码" class="pwd"></el-input>
							<el-button type="primary" round class="downloadBtn" @click="clickSure">确定</el-button>
						</div> -->
					</div>
					<div v-if="androidVersion">
						<div class="info">
							<p v-if="this.androidVersion.versionStr" class="desc">
								Android版本：{{ this.androidVersion.versionStr }}
							</p>
							<span>大小：{{ (this.androidVersion.size / 1024 / 1024).toFixed(1) }}M</span>
						</div>
						<p class="date">Build： {{ this.androidVersion.versionCode }}</p>
						<p class="date">发布日期： {{ this.androidVersion.creatDateStr }}</p>
						<!-- <div v-if="showPasswordInput">
							<el-input v-model="pwd" type="password" placeholder="请输入密码" class="pwd"></el-input>
							<el-button type="primary" round class="downloadBtn" @click="clickSure">确定</el-button>
						</div> -->
					</div>

					<el-button 
						v-if="showDownLoadBtn" 
						class="downloadBtn" 
						type="primary" 
						round 
						@click="clickDownLoadBtn"
					>
						<i :class="platformStr === 'ios' ? 'icon-ic_ios' : 'icon-ic_andr'"></i> 下载安装
					</el-button>
				</div>
			</div>

			<!--右侧二维码-->
			<div v-show="!isPhone" class="preview-mobilewrapper">
				<img class="mobieImg" src="../../assets/ic_mobilphone.png" />
				<vue-qr class="qrcodeImg" :text="downloadUrl" :margin="20"></vue-qr>
				<p class="codetips">请扫描二维码下载APP</p>
				<div>
					<p v-if="iosVersion" class="platform">适用于ios系统</p>
					<p v-if="androidVersion" class="platform" style="top: 420px">适用于android系统</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/ecmascript-6">
import * as AppResourceApi from '../../api/moudle/appResourceApi';
import VueQr from 'vue-qr';
import PhoneWrapper from './phoneWrapper.vue';

export default {
	components: {
		VueQr,
		PhoneWrapper,
	},
	data() {
		return {
			info: {},
			// versionArr: [],
			// appVersionInfo: {},
			// appBaseData: null,
			// name: '',
			// iosVersionInfo: {},
			// androidVersionInfo: {},
			// icon: '',
			// downloadUrl: '',
			// platformStr: '',
			pwd: '',
			isPhone: false,
			userAgent: navigator.userAgent,
		};
	},
	computed: {
		downloadUrl() {
			return `${window.origin}${this.$route.fullPath}`;
		},
		iosVersion() {
			return this.info?.ios?.version;
		},
		androidVersion() {
			return this.info?.android?.version;
		},
		name() {
			return this.info?.name;
		},
		icon() {
			return this.info?.icon;
		},
		isIos() {
			return this.getMobileOS() == 'iOS';
		},
		isSafari() {
			var ua = navigator.userAgent.toLowerCase();
			var isSafari = false;
			if (
				ua.indexOf('applewebkit') > -1 &&
				ua.indexOf('safari') > -1 &&
				ua.indexOf('linux') === -1 &&
				ua.indexOf('android') === -1
			) {
				isSafari = true;
			}
			return isSafari;
		},
		isAndroid() {
			return this.getMobileOS() == 'Android';
		},
		showDownLoadBtn() {
			// mac端不显示，密码安装且密码不正确时不显示
			var p = navigator.platform;
			if (p.indexOf('Mac') === 0) {
				return false;
			} else {
				// if (this.appBaseData.installWithPwd !== 1 || this.pwd === this.appBaseData.installPwd) {
				return true;
				// } else {
				// 	return false;
				// }
			}
		},
		showPasswordInput() {
			if (this.appBaseData.installWithPwd === 1 && this.pwd !== this.appBaseData.installPwd) {
				// 密码安装,且密码不对的情况下展示，其他情况都隐藏
				return true;
			}
			return false;
		},
	},
	mounted() {
		this.getAppInfo(this.$route.params.id);

		// 判断是否是手机设备
		if (this.isIos || this.isAndroid) {
			this.isPhone = true;
		} else {
			this.isPhone = false;
		}
	},
	created() {
		this.$nextTick(() => {});
	},
	methods: {
		// 验证企业
		getMobileOS() {
			const ua = navigator.userAgent;
			if (/android/i.test(ua)) {
				return 'Android';
			}
			else if ((/iPad|iPhone|iPod/.test(ua)) 
				|| (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
				return 'iOS';
			}
			return 'Other';
		},
		checkWXMember() {},
		getTableBackground(index) {
			if (index % 2 === 0) {
				return 'backgroundColor: rgb(244, 245, 247)';
			} else {
				return 'backgroundColor: white';
			}
		},
		getAppInfo(shortUrl) {
			AppResourceApi.getAppGroupByShortUrl(shortUrl).then(
				(res) => {
					// AppResourceApi.getAppInfoByShortUrl(shortUrl).then((res) => {
					console.log(res);
					this.info = res.data;
					if (this.info.ios) {
						let releaseDate = new Date(this.iosVersion.uploadAt);
						this.iosVersion.creatDateStr = `${releaseDate.getFullYear()}-${
							releaseDate.getMonth() + 1
						}-${releaseDate.getDate()}`;
					}
					if (this.info.android) {
						let releaseDate = new Date(this.androidVersion.uploadAt);
						this.androidVersion.creatDateStr = `${releaseDate.getFullYear()}-${
							releaseDate.getMonth() + 1
						}-${releaseDate.getDate()}`;
					}
					// if (res.data.version === null) {
					// 	this.$message.error('未检测到版本信息');
					// 	return;
					// }
					// // this.appVersionInfo = res.data.version;
					// // this.appBaseData = res.data.app;
					// this.name = res.data.name;
					// this.icon = res.data.icon;
					// this.downloadUrl = `${window.origin}${this.$route.fullPath}`;
					// this.iosVersionInfo = res.data.ios.version;
					// this.androidVersionInfo = res.data.android.version;
					// // this.platformStr = res.data.app.platform;

					// let iosReleaseDate = new Date(this.iosVersionInfo.uploadAt);
					// this.iosVersionInfo.creatDateStr = `${iosReleaseDate.getFullYear()}-${iosReleaseDate.getMonth() + 1}-${iosReleaseDate.getDate()}`;

					// let androidReleaseDate = new Date(this.androidVersionInfo.uploadAt);
					// this.iosVersionInfo.creatDateStr = `${androidReleaseDate.getFullYear()}-${androidReleaseDate.getMonth() + 1}-${androidReleaseDate.getDate()}`;

					// if (this.appBaseData.installPwd === 1) {
					// 	this.installWithPwd = true;
					// } else {
					// 	this.installWithPwd = false;
					// }
				},
				(reject) => {
					this.$message.error('服务器错误');
				}
			);
		},
		getIconUrl() {
			return `${this.icon}`;
		},
		clickDownLoadBtn() {
			if (this.isIos) {
				if (this.isSafari === false) {
					alert('请点击右上角，选择”在Safari中打开“');
					return;
				}
				const a = document.createElement('a');
				let plistUrl = `itms-services://?action=download-manifest&url=${this.axios.defaults.baseURL}api/plist/${this.iosVersion.id}`;
				console.log(plistUrl);
				// a.setAttribute('href', this.appVersionInfo.installUrl)
				a.setAttribute('href', plistUrl);
				a.click();
			} else {
				const a = document.createElement('a');
				let url = `${this.axios.defaults.baseURL}api/version/${this.androidVersion.id}/download`;
				a.setAttribute('href', url);
				a.click();
				let _this = this;
				fetch(url).then((response) => {
					var reader = response.body.getReader();
					var headers = response.headers;
					var totalLength = headers.get('Content-Length');
					var bytesReceived = 0;
					reader.read().then(function processResult(result) {
						if (result.done) {
							console.log('下载完成');
							console.log(_this.appBaseData);
							AppResourceApi.downloadedCount(_this.appBaseData.id, _this.appVersionInfo.id).then(
								() => {},
								(reject) => {}
							);
							return;
						}
						bytesReceived += result.value.length;
						console.log(`progress: ${(bytesReceived / totalLength) * 100}%`);
						return reader.read().then(processResult);
					});
				});
			}
		},
		getContentClass() {
			// 判断是否是手机设备
			if (this.isPhone) {
				return 'preview-middlewrapper-forPhone';
			} else {
				return 'preview-middlewrapper';
			}
		},
		getLeftClass() {
			if (this.isPhone) {
				return 'left-forPhone';
			} else {
				return 'left';
			}
		},
		clickSure() {
			if (this.pwd !== this.appBaseData.installPwd) {
				this.$message.error('密码错误');
			}
		},
	},
};
</script>

<style lang="scss">
@import '../../common/scss/base';
body {
	background-color: white;
}
/*网页样式*/
.previewapp-wrapper {
	position: absolute;
	background-color: white;
	width: 100%;
	height: 100%;
	background-image: url('../../assets/bg_picture.png');
	background-size: cover;
}
.preview-middlewrapper {
	margin-top: 0px;
	margin-left: 25%;
	width: 50%;
	height: 100%;
	max-width: 800px;
	text-align: center;
	position: absolute;
	font-size: 0px;
}
.preview-middlewrapper-mobile {
	margin-top: 0px;
	margin-left: 25%;
	width: 50%;
	height: 100%;
	max-width: 800px;
	text-align: center;
	position: absolute;
	font-size: 0px;
}
.preview-middlewrapper .left {
	display: inline-block;
	width: 50%;
	height: 100%;
	vertical-align: top;
	text-align: left;
}
.preview-mobilewrapper {
	display: inline-block;
	width: 50%;
	height: 100%;
	vertical-align: top;
	position: relative;
	text-align: center;
}

.preview-mobilewrapper > img {
	margin-top: 120px;
	width: 300px;
	position: absolute;
	left: 0px;
	height: auto;
}

.pcWrapper .appicon {
	width: 126px;
	height: 126px;
	border-radius: 15px;
	margin-top: 160px;
}
.previewapp-wrapper .title {
	color: #354052;
	font-weight: bold;
	font-size: 26px;
	height: 37px;
	line-height: 37px;
	margin-top: 33px;
}
.pcWrapper .info {
	display: flex;
	flex-direction: row;
	color: #242a34;
	font-size: 14px;
	line-height: 20px;
	margin-top: 12px;
	opacity: 0.5;
}
.previewapp-wrapper .desc {
	margin-right: 12px;
}
.preview-middlewrapper .date {
	color: #242a34;
	font-size: 14px;
	text-align: left;
	line-height: 20px;
	margin-top: 2px;
	opacity: 0.5;
}
.preview-middlewrapper .pwd {
	width: 70%;
	height: 40px;
	border-radius: 6px;
	margin-top: 12px;
	background-color: transparent;
	color: red;
}
.preview-middlewrapper .downloadBtn {
	background-color: #8393f5;
	width: 70%;
	height: 44px;
	color: white;
	font-size: 14px;
	margin-top: 16px;
	border-color: transparent;
}
.downloadBtn i:before {
	color: white;
}
.preview-mobilewrapper .qrcodeImg img {
	position: absolute;
	width: 150px;
	height: auto;
	left: 32px;
	margin-top: 200px;
}

.preview-mobilewrapper .codetips {
	color: #354052;
	font-size: 14px;
	text-align: center;
	line-height: 20px;
	height: 20px;
	left: 35px;
	top: 380px;
	position: absolute;
}
.preview-mobilewrapper .platform {
	color: #354052;
	opacity: 0.5;
	font-size: 14px;
	text-align: center;
	line-height: 20px;
	height: 20px;
	left: 60px;
	top: 400px;
	position: absolute;
}

/*手机样式*/
.preview-middlewrapper-forPhone {
	margin-top: 0px;
	width: 100%;
	height: 100%;
	text-align: center;
	position: absolute;
	font-size: 0px;
}
.preview-middlewrapper-forPhone .left-forPhone {
	display: inline-block;
	width: 100%;
	height: 100%;
	vertical-align: top;
	text-align: center;
}
</style>
