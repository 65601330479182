<template>
	<div class="phoneDownLoadWrapper">
		<img class="appicon" :src="getIconUrl()" alt="">
		<p class="title">{{ info.name }}</p>
		<div class="info">
			<p class="desc">版本：{{ version.versionStr }}</p><span>大小：{{ (version.size/1024/1024).toFixed(1) }}M</span>
		</div>
		<div v-if="!showDownLoadBtn">
			<div>
				<el-input v-model="pwd" type="password" placeholder="请输入密码" class="pwd"></el-input>
			</div>
			<div>
				<el-button type="primary" round class="downloadBtn sureBtn" @click="clickSure">确定</el-button>
			</div>
		</div>

		<el-button v-if="showDownLoadBtn" class="downloadBtn" type="primary" round @click="clickDownLoadBtn"><i :class="isIos ? 'icon-ic_ios':'icon-ic_andr'"></i>    下载安装</el-button>
	</div>
</template>

<script type="text/ecmascript-6">
export default {
	props: {
		info: {
			type: Object
		},
		// appVersionInfo: {
		// 	type: Object
		// },
		// platformStr: {
		// 	type: String
		// }
	},
	data() {
		return {
			pwd: ''
		};
	},
	computed: {
		isIos() {
			return this.getMobileOS() == 'iOS';
		},
		isAndroid() {
			return this.getMobileOS() == 'Android';
		},
		isSafari() {
			var ua = navigator.userAgent.toLowerCase();
			var isSafari = false;
			if (ua.indexOf('applewebkit') > -1 && 
				ua.indexOf('mobile') > -1 && 
				ua.indexOf('safari') > -1 && 
				ua.indexOf('linux') === -1 && 
				ua.indexOf('android') === -1) {
				isSafari = true;
			}
			return isSafari;
		},
		showDownLoadBtn() {
			if (this.info.installWithPwd !== 1 || this.pwd === this.info.installPwd) {
				return true;
			} else {
				return false;
			}
		},
		version() {
			if (this.isIos) {
				return this.info?.ios?.version;
			} else if (this.isAndroid) {
				return this.info?.android?.version;
			} else {
				return undefined;
			}
		},
	},
	created() {},
	methods: {
		getIconUrl() {
			return `${this.info.icon}`;
		},
		clickSure() {
			if (this.pwd !== this.info.installPwd) {
				this.$message.error('密码错误');
			}
		},
		clickDownLoadBtn() {
			this.$emit('clickDownLoadBtn');
		},
		getMobileOS() {
			const ua = navigator.userAgent;
			if (/android/i.test(ua)) {
				return 'Android';
			}
			else if ((/iPad|iPhone|iPod/.test(ua)) 
				|| (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
				return 'iOS';
			}
			return 'Other';
		}
	}
};
</script>

<style lang="scss">
	@import "../../common/scss/base";

	.phoneDownLoadWrapper {
	}
	.phoneDownLoadWrapper .appicon {
		width: 126px;
		height: 126px;
		border-radius: 15px;
		margin-top: 100px;
	}
	.phoneDownLoadWrapper .info {
		display: flex;
		flex-direction: row;
		justify-content: center;
		color: #242A34;
		font-size: 14px;
		line-height: 20px;
		margin-top: 12px;
		opacity: 0.5;
	}
	.phoneDownLoadWrapper .pwd {
		width: 200px;
		height: 40px;
		border: solid 1px $mainColor;
		border-radius: 6px;
		margin-top: 12px;
		background-color: transparent;
	}
	.phoneDownLoadWrapper .downloadBtn {
		background-color: #8393F5;
		width: 200px;
		height: 44px;
		color: white;
		font-size: 14px;
		margin-top: 20px;
		border-color: transparent;
	}
</style>
